<div class="pt-65px">
    <!--  -->
    <div class="container pb-3">
        <h2 class="display-4 m-0">CSP Hash Digest Generator</h2>
        <p class="lead">Generate CSP hashes for script-src and style-src.</p>
        
        <p>Insert the exact content with or without the &lt;script> or &lt;style> tags. Make sure to include all spacing and newlines. The hash will be automatically generated as you type.</p>

        <div class="form-group">
        <textarea
            class="form-control"
            rows="5"
            placeholder="var a = 1;"
            [(ngModel)]="content" (keyup)="updateHash(content)"> </textarea>
        </div>

        <div *ngIf="hash">
            <p>Include the following hash (with the single quotes) to either the 'script-src' or 'style-src' of your policy:</p>
            <div class="card bg-primary text-white" *ngIf="hash">
                <div class="card-body">
                    'sha256-{{hash}}'
                </div>
            </div>
        <!-- <p></p> -->
        </div>

    </div>
    <div class="container">
        <h3 class="display-5">F.A.Q.</h3>

        <div class="row">
            <div class="col-md-12">
              <div class="card border-0">
                <div class="card-header bg-white border-0 font-weight-bold mb-0 pb-0">What do I do with the hash?</div>
                <div class="card-body pt-1">
                  <p class="card-text">The hash should be included into your Content-Security-Policy under the correct directive (script-src or style-src). As an example:</p>
                  <app-policy-view policyStr="script-src  https://cdn.com/jquery.js 'sha256-V6MU1O5VoBoCf5CCcSUEq0uDdOkHNn9qvhMNzKLle2o='; style-src https://cdn.com/styles.css 'sha256-9PK+x51HIBJTF8W3h1GfrMo58ngBW77+9GoJi1XM6sw='
                  ; report-uri https://my.endpoint.csper.io/"></app-policy-view>
                </div>
              </div>
                    
              <div class="card border-0">
                <div class="card-header bg-white border-0 font-weight-bold mb-0 pb-0">Is it better to move inline javascript to a seperate file or use a hash?</div>
                <div class="card-body pt-1">
                  <p class="card-text">
                    I recommend moving all inline scripts to a seperate file, but using a hash can be good for getting started. If the Javascript changes, the hash will need to be updated, which can cause issues if not everyone on the team is familiar with CSP. But if the javascript is in a file, it can be modified without issues.
                  </p>

                </div>
              </div>

              <div class="card border-0">
                <div class="card-header bg-white border-0 font-weight-bold mb-0 pb-0">How can I check if the hash is working?</div>
                <div class="card-body pt-1">
                  <p class="card-text">
                    You can check if the hash is working by opening the browser console. If the hash is incorrect, you will see a message in the console. If the hash is correct, you will not see any messages. If the hash is incorrect the browser will tell you the correct hash. 
                </p>

                <p class="card-text">To see what this looks like, open the browser console on this current page. The current page is intentionally missing the following script-src 'sha256-eDD3ARH7WqXlzieuQm69j+6EQuVXw8PhWpK3CE8BvIs='.</p>
                </div>
              </div>

          
              <div class="card border-0">
                <div class="card-header bg-white border-0 font-weight-bold mb-0 pb-0">What if the javascript is an event handler?</div>
                <div class="card-body pt-1">
                  <p class="card-text">If you'd like to use inline event handlers (for example an 'onclick' handler), you will need to include the 'unsafe-hashes' directive as part of script-src.</p>
                </div>
              </div>


              <div class="card border-0">
                <div class="card-header bg-white border-0 font-weight-bold mb-0 pb-0">Are there other ways to generate the hash?</div>
                <div class="card-body pt-1">
                  <p class="card-text">Yes, it is just 'sha256-' + base64(sha256(content)). Every (modern/reasonable) programming language has support for both base64 and sha256. Here's an example for generating the hash in bash using openssl:</p>
                  <pre><code> echo -n 'var a = 1;' | openssl dgst -binary -sha256 | openssl base64 </code></pre>
                </div>
              </div>
          
              <div class="card border-0">
                <div class="card-header bg-white border-0 font-weight-bold mb-0 pb-0">Where can I learn more?</div>
                    <div class="card-body pt-1">
                    <p class="card-text">For any specific technical questions, I recommend checking out the <a href="https://www.w3.org/TR/CSP3/">CSPv3 spec</a>. If it's a browser specific question, the browser vendors have their own documentation which is also quite good. If you are a Csper customer, feel free to email support.</p>
                    </div>
              </div>
            </div>



        
    </div>

</div>

<app-footer></app-footer>