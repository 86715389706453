// @ts-nocheck
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Router, NavigationEnd } from "@angular/router";
import { Organization } from "../models/organization";

declare var mixpanel: any;
declare var FS: any;

window.dataLayer = window.dataLayer || [];
function gtag() {
  dataLayer.push(arguments);
}

@Injectable({
  providedIn: "root",
})
export class TrackService {
  isFSEnabled: boolean;

  constructor(private router: Router) {
    this.isFSEnabled = false;

    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
  }

  // enableHotJar() {
  //   (function (h, o, t, j, a, r) {
  //     h.hj =
  //       h.hj ||
  //       function () {
  //         (h.hj.q = h.hj.q || []).push(arguments);
  //       };
  //     h._hjSettings = { hjid: 1689163, hjsv: 6 };
  //     a = o.getElementsByTagName("head")[0];
  //     r = o.createElement("script");
  //     r.async = 1;
  //     r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
  //     a.appendChild(r);
  //   })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
  // }

  // enableGA() {
  //   //@ts-ignore
  //   if (typeof gtag != "undefined") {
  //     gtag("js", new Date());
  //     gtag("config", environment.gtag);

  //     this.router.events.subscribe((event) => {
  //       if (event instanceof NavigationEnd) {
  //         gtag("config", environment.gtag, {
  //           page_path: event.urlAfterRedirects,
  //         });
  //       }
  //     });
  //   }
  // }

  identify(userID, email, name, method: string, isFirstLogin: boolean) {
    if (typeof FS != "undefined" && FS.identify) {
      FS.identify(userID, {
        displayName: name,
        email: email,
      });
    }

    if (typeof gtag != "undefined") {
      gtag("config", environment.gtag, { user_id: userID });

      if (isFirstLogin) {
        gtag("event", "register", { method: method });
      }

      // else {
      //   gtag('event', 'login', { 'method': method });
      // }
    } else {
      console.log("no gtag");
    }
  }

  trackStartCheckout(organization: Organization) {
    let pricing = 0;
    if (organization.planName == "BASIC_100000") {
      pricing = 25.0;
    } else if (organization.planName == "BUSINESS_1000000") {
      pricing = 100.0;
    } else if (organization.planName == "PERSONAL_10000") {
      pricing = 7.0;
    }

    if (typeof gtag != "undefined") {
      gtag("event", "begin_checkout", {
        transaction_id: organization.checkoutSessionID,
        affiliation: "Csper",
        value: pricing,
        currency: "USD",
        shipping: 0,
        items: [
          {
            id: organization.subscriptionID,
            name: organization.planName,
            brand: "Csper",
            category: "SaaS",
            quantity: 1,
            price: pricing,
          },
        ],
      });
    }
  }

  trackPurchase(organization: Organization) {
    let pricing = 0;
    if (organization.planName == "BASIC_100000") {
      pricing = 25.0;
    } else if (organization.planName == "BUSINESS_1000000") {
      pricing = 100.0;
    } else if (organization.planName == "PERSONAL_10000") {
      pricing = 7.0;
    }

    if (typeof gtag != "undefined") {
      gtag("event", "purchase", {
        transaction_id: organization.checkoutSessionID,
        affiliation: "Csper",
        value: pricing,
        currency: "USD",
        shipping: 0,
        items: [
          {
            id: organization.subscriptionID,
            name: organization.planName,
            brand: "Csper",
            category: "SaaS",
            quantity: 1,
            price: pricing,
          },
        ],
      });
    }
  }

  // mtrack(event : string) {
  //   mixpanel.track(event)
  // }
}
