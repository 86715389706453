import { Component } from '@angular/core';

@Component({
  selector: 'app-hasher',
  templateUrl: './hasher.component.html',
  styleUrls: ['./hasher.component.css']
})
export class HasherComponent {

  content: string;
  hash: string;

  updateHash(content: string) {
    if (!content) {
      this.hash = '';
      return;
    }

    if (content.trim().toLowerCase().startsWith('<script>')) {
      content = content.trim()
      content = content.substring('<script>'.length, content.length - '</script>'.length);
    }

    if (content.trim().toLowerCase().startsWith('<style>')) {
      content = content.trim()
      content = content.trim().substring('<style>'.length, content.length - '</style>'.length);
    }

    console.log("Generating hash for:" + content)

    const encoder = new TextEncoder();
    const data = encoder.encode(content);

    crypto.subtle.digest('SHA-256', data).then(hash => {
      this.hash = btoa(String.fromCharCode.apply(null, new Uint8Array(hash)));
    });

  }

}
